import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import Loader from '../components/Loader';

import { hasRequiredPermissions } from './permissionFunctions';

const RouteWrapper = ({
  isPrivate,
  component: Component,
  or,
  requiredPermissions,
  ...rest
}) => {
  const { loggedUser, getLoggedUser } = useAuth();
  const authenticated = useAuth().isAuthenticated();

  useEffect(() => {
    if (loggedUser || !authenticated) return;

    getLoggedUser();
  }, [authenticated, getLoggedUser, loggedUser]);

  return loggedUser || !authenticated ? (
    <Route
      {...rest}
      render={() => {
        if (isPrivate) {
          if (!authenticated) {
            return <Redirect to="/login" />;
          }

          if (loggedUser) {
            if (requiredPermissions && requiredPermissions.length > 0) {
              const authorized = hasRequiredPermissions(loggedUser, requiredPermissions, [], or); // prettier-ignore
              if (!authorized || !loggedUser.active) {
                return <Redirect to="/unauthorized" />;
              }
            }
          }
        }

        return <Component />;
      }}
    />
  ) : (
    <div id="page-loading">
      <Loader size="large" />
    </div>
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  or: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  or: false,
  requiredPermissions: [],
};

export default RouteWrapper;
