export default {
  name: 'backoffice',
  modules: [
    'bi',
    'executions',
    'clients',
    'finances',
    'datasources',
    'backoffice_clientWorkflows',
    'hub_accounts',
  ],
};
