import React from 'react';

import { AuthProvider } from './auth';
import { ScrollProvider } from './scroll';
import { PolicyProvider } from './policy';
import { UserProvider } from './user';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <PolicyProvider>
      <UserProvider>
        <ScrollProvider>{children}</ScrollProvider>
      </UserProvider>
    </PolicyProvider>
  </AuthProvider>
);

export default AppProvider;
