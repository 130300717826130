import React from 'react';
import { Eye, Edit, Add, Trash } from '@combateafraude/icons/general';

const HeaderInfo = () => (
  <div className="custom-collapse-icons">
    <Eye />
    <Edit />
    <Add />
    <Trash />
  </div>
);

export default HeaderInfo;
