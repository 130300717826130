import React, { createContext, useState, useCallback, useContext } from 'react';
import { message } from 'antd';

import api from 'services/api';
import { useFetch } from 'services/hooks';

const PolicyContext = createContext({});

const PolicyProvider = ({ children }) => {
  const [policy, setPolicy] = useState();
  const [listPolicies, setListPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const { get } = useFetch();

  const clearData = useCallback(() => {
    setPolicy();
  }, []);

  const loadPolicy = useCallback(
    async ({ id, clearData: clearDataParam }) => {
      try {
        if (clearDataParam) clearData();

        setLoading(true);

        const { data: response } = await api.get(
          `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups/${id}`
        );

        setPolicy(response?.data);
      } catch (err) {
        if (err?.response?.status === 404) {
          const errMessage =
            err?.response?.data?.message || 'Política de acesso não encontrada.';
          message.error(errMessage);
        } else {
          message.error('Houve um problema ao buscar os dados da política de acesso.');
        }
      } finally {
        setLoading(false);
      }
    },
    [clearData]
  );

  const getListPolicies = useCallback(
    async (config = undefined) => {
      try {
        setLoading(true);

        const response = await get({
          url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups`,
          config,
        });

        setListPolicies(response?.docs);
      } catch (err) {
        if (err?.response?.status === 404) {
          const errMessage =
            err?.response?.data?.message || 'Políticas de acesso não encontradas.';
          message.error(errMessage);
        } else {
          message.error('Houve um problema ao buscar os dados das políticas de acesso.');
        }
      } finally {
        setLoading(false);
      }
    },
    [get]
  );

  return (
    <PolicyContext.Provider
      value={{
        policy,
        loadPolicy,
        loading,
        setLoading,
        clearData,
        listPolicies,
        getListPolicies,
      }}
    >
      {children}
    </PolicyContext.Provider>
  );
};

function usePolicy() {
  const context = useContext(PolicyContext);

  if (!context) {
    throw new Error('usePolicy must be used within an PolicyProvider');
  }

  return context;
}

export { PolicyProvider, usePolicy };
