/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo } from 'react';
import { message, Modal } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import api from 'services/api';

const ChangeUserStatus = ({ refreshListRef }) => {
  const [user, setUser] = useState();
  const [isActive, setIsActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setUser(undefined);
  }, []);

  const openModal = useCallback((record) => {
    setIsActive(record?.active);
    setUser(record);
    setModalVisible(true);
  }, []);

  const onChangeUserStatus = useCallback(async () => {
    try {
      setLoadingRemoveUser(true);

      await api.patch(
        `${process.env.REACT_APP_BASE_URL_BIFROST_API}/users/${user?.username}`,
        {
          active: !isActive,
        }
      );

      refreshListRef.current();
      closeModal();
      message.success(
        isActive ? 'Usuário inativado com sucesso!' : 'Usuário reativado com sucesso!'
      );
    } catch (err) {
      message.error(
        isActive
          ? 'Houve um problema ao inativar este usuário.'
          : 'Houve um problema ao reativar este usuário.'
      );
      closeModal();
    } finally {
      setLoadingRemoveUser(false);
    }
  }, [user, refreshListRef, closeModal, isActive]);

  const ChangeUserStatusModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle
            title={isActive ? 'Inativar usuário' : 'Reativar usuário'}
            align="center"
          />
          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              {isActive
                ? 'Você realmente deseja inativar esse usuário?'
                : 'Você realmente deseja reativar esse usuário?'}
              <strong className="display-block mrg-top-10 text-dark font-size-16">
                {user?.name} ({user?.username})
              </strong>
            </span>
          </div>

          <div className="flex center-end">
            <Button
              type="default"
              onClick={closeModal}
              className="btn-cancel"
              disabled={loadingRemoveUser}
            >
              Cancelar
            </Button>
            <Button
              type={isActive ? 'danger' : 'primary'}
              onClick={onChangeUserStatus}
              disabled={loadingRemoveUser}
              loading={loadingRemoveUser}
            >
              {loadingRemoveUser
                ? 'Aguarde...'
                : isActive
                ? 'Sim, inativar'
                : 'Sim, reativar'}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, isActive, user, loadingRemoveUser, onChangeUserStatus]
  );

  return { openModal, closeModal, ChangeUserStatusModal };
};

export default ChangeUserStatus;
