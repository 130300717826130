import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import { Edit, Trash, MoreVertical } from '@combateafraude/icons/general';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import PermissionWrapper from 'routes/PermissionWrapper';
import { useAuth } from 'hooks/auth';

import useRemovePolicy from './RemovePolicy';

const PoliciesActionsList = ({ policy, refreshListRef }) => {
  const { openModal, RemovePolicyModal } = useRemovePolicy({ policy, refreshListRef });
  const { loggedUser } = useAuth();

  return (
    <>
      {RemovePolicyModal}
      <PermissionWrapper requiredPermissions={['policies:update', 'policies:delete']} or>
        <Dropdown
          overlay={
            <Menu className="pdd-vertical-5">
              {hasRequiredPermissions(loggedUser, ['policies:update'], []) && (
                <Menu.Item key="editPolicy" className="text-dark">
                  <Link to={`/policies/${policy?.id}`}>
                    <Edit />
                    <span className="text-semibold">Editar política de acesso</span>
                  </Link>
                </Menu.Item>
              )}

              {hasRequiredPermissions(
                loggedUser,
                ['policies:delete', 'policies:update'],
                []
              ) && <Menu.Divider className="mrg-horizon-15 mrg-vertical-5" />}

              {hasRequiredPermissions(loggedUser, ['policies:delete'], []) && (
                <Menu.Item
                  key="deletePolicy"
                  className="gx-text-danger"
                  onClick={openModal}
                >
                  <Trash />
                  <span className="text-semibold ">Excluir política</span>
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-more-icon">
            <MoreVertical />
          </div>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
};

PoliciesActionsList.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  refreshListRef: PropTypes.shape({
    current: PropTypes.func.isRequired,
  }).isRequired,
};

PoliciesActionsList.defaultProps = {
  policy: {},
};

export default PoliciesActionsList;
