import React, { useEffect, useState, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Button } from 'antd';
import { useAuth } from 'hooks/auth';

import api from 'services/api';
import Loader from 'components/Loader';

import loginImg from 'assets/images/login.png';
import logo from 'assets/images/logo-login.svg';
import logoDark from 'assets/images/logo-login-dark.svg';
import logoGoogle from 'assets/images/logo-google.svg';

import './styles.less';

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [signInEnded, setSignInEnded] = useState(false);
  const location = useLocation();
  const { saveCredentials, isDarkTheme } = useAuth();
  const authenticated = useAuth().isAuthenticated();

  useEffect(() => {
    setTimeout(() => {
      if (!authenticated) setLoading(false);
    }, 3000);
    const signIn = async (code, redirectUri) => {
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL_BIFROST_API}/users/signin/${code}?redirect_uri=${redirectUri}`
      );

      const { idToken, refreshToken } = response.data;

      saveCredentials(idToken, refreshToken);
      setSignInEnded(true);
    };

    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      signIn(code, `${process.env.REACT_APP_BASE_URL_BIFROST_WEB}/login`);
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(() => {
    const redirectUri = encodeURIComponent(
      `${process.env.REACT_APP_BASE_URL_BIFROST_WEB}/login`
    );
    window.location.href = `${process.env.REACT_APP_BASE_URL_COGNITO}${redirectUri}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signInEnded) {
      setLoading(false);
    }
  }, [signInEnded]);

  return !loading ? (
    <div id="login-component">
      {authenticated && <Redirect to="/users" />}
      <div className="login">
        <img src={isDarkTheme ? logoDark : logo} alt="Logo Wecheck" />

        <Button onClick={login} loading={loading} className="loginGoogle">
          <img src={logoGoogle} alt="Logo Wecheck" />
          Continue with Google
        </Button>
      </div>
      <div className="login-img">
        <img src={loginImg} alt="Logo Google" />
      </div>
    </div>
  ) : (
    <div id="page-loading">
      <Loader size="large" />
    </div>
  );
};

export default Login;
