import axios from 'axios';
import Cookies from 'js-cookie';

const isLocalhost = !!window.location.origin.startsWith('http://localhost');

const ID_TOKEN = isLocalhost
  ? `CAF-Bifrost-${process.env.REACT_APP_ENV}_id_token`
  : `__Secure-CAF-Bifrost-${process.env.REACT_APP_ENV}_id_token`;
const id_token = Cookies.get(ID_TOKEN);

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BIFROST_API,
  headers: {
    Authorization: id_token,
  },
});

export const cleanApi = axios.create();

export default api;
