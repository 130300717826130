/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Collapse } from 'antd';

import HeaderInfo from './HeaderInfo';
import HeaderSwitch from './HeaderSwitch';

const { Panel } = Collapse;

const CustomCollapse = ({
  active,
  children,
  ruleName,
  disabled,
  className,
  title,
  icon,
}) => {
  const [isActive, setIsActive] = useState(active);

  return (
    <Collapse
      activeKey={isActive && [`${ruleName}-switch`]}
      className={className}
      destroyInactivePanel
      ghost
    >
      <Panel
        showArrow={false}
        collapsible={!isActive ? 'disabled' : 'header'}
        key={`${ruleName}-switch`}
        header={
          <HeaderSwitch
            title={title}
            icon={icon}
            name={[ruleName, 'switch']}
            isActive={isActive}
            setIsActive={setIsActive}
            disabled={disabled}
          />
        }
      >
        <div className="custom-collapse-icons">
          <HeaderInfo />
        </div>
        <div className="mrg-top-15 custom-collapse-items">{children}</div>
      </Panel>
    </Collapse>
  );
};

export default CustomCollapse;
