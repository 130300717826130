import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';

const HorizontalNav = () => (
  <>
    <Menu className="menu-container" mode="horizontal">
      <PermissionWrapper requiredPermissions={['users:read']}>
        <Menu.Item key="users">
          <NavLink
            to="/users"
            className={(isActive) => `nav-link${!isActive ? ' unselected' : ''}`}
          >
            Usuários
          </NavLink>
        </Menu.Item>
      </PermissionWrapper>

      <PermissionWrapper requiredPermissions={['policies:read']}>
        <Menu.Item key="policies">
          <NavLink
            to="/policies"
            className={(isActive) => `nav-link${!isActive ? ' unselected' : ''}`}
          >
            Políticas de acesso
          </NavLink>
        </Menu.Item>
      </PermissionWrapper>
    </Menu>
  </>
);

export default HorizontalNav;
