/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const OnboardingBuilderPanel = ({ productActive, disabled, formRef }) => (
  <CustomCollapse
    active={productActive}
    ruleName="onboarding_builder"
    disabled={disabled}
    className="no-border-top custom-collapse"
    title="Onboarding Builder"
    icon="Onboarding"
  >
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Geral"
      icon="Settings"
      ruleName="general"
      availability={[true, true, true, true]}
    />
  </CustomCollapse>
);

export default OnboardingBuilderPanel;
