import React, { useState, useCallback, useMemo } from 'react';
import { message, Modal } from 'antd';
import { PageTitle, Button } from '@combateafraude/react';

import api from 'services/api';

const RemovePolicy = ({ policy, refreshListRef }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onRemovePolicy = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(
        `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups/${policy?.id}`
      );
      refreshListRef.current();
      closeModal();
      message.success('Política removida com sucesso!');
    } catch (err) {
      if (err.response.status === 400) {
        message.error(err.response.data.message);
      } else {
        message.error('Houve um problema ao excluir esta política de acesso.');
      }
      closeModal();
    } finally {
      setLoading(false);
    }
  }, [policy, refreshListRef, closeModal]);

  const RemovePolicyModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        footer={null}
        width={550}
        closable={false}
        wrapClassName="caf-modal"
        onCancel={closeModal}
      >
        <div className="no-mrg no-pdd">
          <PageTitle title="Excluir política" align="center" />

          <div className="flex center mrg-top-25 mrg-btm-35">
            <span className="text-center font-size-16">
              Você realmente deseja excluir essa política de acesso?
              <strong className="display-block mrg-top-10 text-dark font-size-16">
                {policy?.name}
              </strong>
            </span>
          </div>

          <div className="flex end-center">
            <Button
              outlined
              onClick={closeModal}
              className="btn-cancel"
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              type="danger"
              onClick={onRemovePolicy}
              disabled={loading}
              loading={loading}
              className="mrg-left-10"
            >
              {loading ? 'Aguarde...' : 'Sim, excluir'}
            </Button>
          </div>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, policy, loading, onRemovePolicy]
  );

  return { openModal, closeModal, RemovePolicyModal };
};

export default RemovePolicy;
