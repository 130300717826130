/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState, createElement } from 'react';
import { Row, Col, Form, Checkbox, Tooltip } from 'antd';
import { Info } from '@combateafraude/icons/general';
import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

import { useAuth } from 'hooks/auth';

const PolicyRow = ({
  title,
  icon,
  ruleName,
  formRef,
  availability = [],
  disabled,
  allowedTenants = [],
  ResourcesComponent,
}) => {
  const { loggedUser } = useAuth();

  const Icons = Object.assign(iconsGeneral || {}, iconsUsers || {}, iconsFace || {});

  const [otherChecks, setOtherChecks] = useState({
    create: formRef.getFieldValue([ruleName, 'create']),
    update: formRef.getFieldValue([ruleName, 'update']),
    delete: formRef.getFieldValue([ruleName, 'delete']),
  });

  const checkReadPermission = useCallback(
    (rule, type, value) => {
      setOtherChecks((oldState) => ({
        ...oldState,
        [type]: value,
      }));

      if (!value) return;
      if (formRef.getFieldValue([rule, 'read'])) return;

      formRef.setFieldsValue({ [rule]: { read: true } });
    },
    [formRef]
  );

  const readIsDisabled = useMemo(
    () =>
      disabled ||
      !availability[0] ||
      otherChecks.create ||
      otherChecks.update ||
      otherChecks.delete,
    [availability, disabled, otherChecks]
  );

  return (
    (allowedTenants.length === 0 || allowedTenants.includes(loggedUser.tenantId)) && (
      <div className="divider-crud">
        <Row>
          <Col flex="auto" style={{ display: 'flex', alignItems: 'center' }}>
            {Object.keys(Icons || {}).includes(icon) && createElement(Icons[icon])}
            {title}
          </Col>

          {ResourcesComponent && (
            <Col flex="250px" className="mrg-right-15">
              {ResourcesComponent}
            </Col>
          )}

          <div className="policies-checkboxes">
            {readIsDisabled && (
              <Col className="col-read-tooltip" flex="19px">
                <Tooltip
                  title={`A ação "Visualizar" é obrigatória pois alguma outra ação está habilitada`}
                >
                  <div>
                    <Info color="#004af7" width={24} height={24} />
                  </div>
                </Tooltip>
              </Col>
            )}

            <Col>
              <Form.Item
                name={[ruleName, 'read']}
                className="no-mrg no-pdd"
                valuePropName="checked"
              >
                <Checkbox disabled={readIsDisabled} />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name={[ruleName, 'update']}
                className="no-mrg no-pdd"
                valuePropName="checked"
              >
                <Checkbox
                  disabled={disabled || !availability[1]}
                  onChange={(value) =>
                    checkReadPermission(ruleName, 'update', value.target.checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name={[ruleName, 'create']}
                className="no-mrg no-pdd"
                valuePropName="checked"
              >
                <Checkbox
                  disabled={disabled || !availability[2]}
                  onChange={(value) =>
                    checkReadPermission(ruleName, 'create', value.target.checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name={[ruleName, 'delete']}
                className="no-mrg no-pdd"
                valuePropName="checked"
              >
                <Checkbox
                  disabled={disabled || !availability[3]}
                  onChange={(value) =>
                    checkReadPermission(ruleName, 'delete', value.target.checked)
                  }
                />
              </Form.Item>
            </Col>
          </div>
        </Row>
      </div>
    )
  );
};

export default PolicyRow;
