import React, { useCallback, useRef, useEffect } from 'react';
import { Form, Select, message } from 'antd';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { Tag, PageTitle } from '@combateafraude/react';

import { Reorder, ArrowDown, Anchor } from '@combateafraude/icons/general';

import _ from 'lodash';
import SearchInput from 'components/Form/SearchInput';
import { useAuth } from 'hooks/auth';
import { usePolicy } from 'hooks/policy';
import { useFetch } from 'services/hooks';
import useDynamoTable from 'components/List/hooks/useDynamoTable';
import PermissionWrapper from 'routes/PermissionWrapper';

import useChangeUserStatus from './components/ChangeUserStatus';
import SwitchRenderer from './components/SwitchRenderer';

import Wrapper from '../wrapper';

const { Option } = Select;

const UsersList = () => {
  const { loggedUser } = useAuth();
  const refreshListRef = useRef(() => {});
  const { patch } = useFetch();

  const { listPolicies, getListPolicies, loading, setLoading } = usePolicy();
  const [query] = useQueryParams({
    search: StringParam,
    active: withDefault(StringParam, `true`),
    policyFilter: StringParam,
  });

  useEffect(() => {
    getListPolicies({
      params: {
        limit: 999,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { openModal: openChangeStatusModal, ChangeUserStatusModal } = useChangeUserStatus(
    {
      refreshListRef,
    }
  );

  const changeUserPolicy = useCallback(
    async (username, policyId) => {
      try {
        await patch({
          url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/users/${username}`,
          payload: { policyGroup: { id: policyId } },
        });
        message.success('Política de acesso do usuário alterada com sucesso!');

        if (loggedUser?.username === username) {
          setLoading(true);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      } catch (err) {
        getListPolicies();
        message.error('Falha ao alterar política de acesso do usuário!');
      }
    },
    [getListPolicies, loggedUser?.username, patch, setLoading]
  );

  const findPolicyById = useCallback(
    (id) => {
      const foundPolicy = listPolicies.find((policy) => policy.id === id);
      return foundPolicy?.name || 'Sem acesso';
    },
    [listPolicies]
  );

  const columns = [
    {
      key: 'name',
      title: (
        <div className="flex row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <div className="flex start-center text-dark">
          {record?.name}
          {record?.username === loggedUser?.username && (
            <Tag className="mrg-left-10">Você</Tag>
          )}
        </div>
      ),
    },
    {
      key: 'email',
      title: (
        <div className="flex row">
          <span className="column-title">Email</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      key: 'policy',
      title: <span className="column-title">Política de acesso</span>,
      dataIndex: 'policy',
      render: (__, record) =>
        !loading && (
          <PermissionWrapper
            requiredPermissions={['users:update']}
            forbiddenContent={
              <span className="text-dark text-bold no-pdd">
                {findPolicyById(record?.policyGroup?.id)}
              </span>
            }
          >
            <Select
              showSearch
              suffixIcon={<ArrowDown />}
              optionFilterProp="children"
              bordered={false}
              style={{ width: '200px' }}
              className="text-dark text-bold no-pdd"
              defaultValue={record?.policyGroup?.id || ''}
              onSelect={(e) => changeUserPolicy(record?.username, e)}
            >
              {listPolicies?.map((policy) => (
                <Option key={policy?.id} value={policy?.id}>
                  {policy?.name}
                </Option>
              ))}
            </Select>
          </PermissionWrapper>
        ),
    },
    {
      key: 'status',
      title: <span className="column-title">Status</span>,
      render: (__, record) => (
        <SwitchRenderer data={record} openModal={openChangeStatusModal} />
      ),
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/users`,
      config: {
        params: {
          limit: 10,
        },
      },
    },
    queryParams: {
      search: StringParam,
      active: withDefault(StringParam, 'true'),
      policyFilter: StringParam,
    },
    columns,
    rowKey: 'username',
    customLoading: loading,
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        active: values.active && values.active !== 'all' ? values.active : undefined,
        search: values._search ? values._search.trim() : undefined,
        policyFilter: values.policyFilter ? values.policyFilter.trim() : undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      {ChangeUserStatusModal}
      <div className="flex gx-w-100 space-between">
        <PageTitle
          title="Usuários do sistema"
          subtitle="Gerencie os usuários da base e suas permissões."
        />
        <div className="flex row mrg-top-15 mrg-btm-30 align-center gx-w-170">
          <Form
            layout="horizontal"
            className="flex"
            onValuesChange={handleFormChange}
            initialValues={{
              _search: query?.search,
              active: query?.active,
              policyFilter: query?.policyFilter,
            }}
          >
            <Form.Item name="policyFilter" className="no-mrg-btm">
              <Select
                showSearch
                allowClear={{ clearIcon: <Anchor color="#292929" /> }}
                suffixIcon={<ArrowDown />}
                bordered={false}
                loading={loading}
                style={{ width: 309, fontWeight: 400, marginRight: 32 }}
                placeholder="Selecione uma Política de Acesso"
                defaultActiveFirstOption={false}
                className="text-dark"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {listPolicies?.map((policy) => (
                  <Option key={policy?.id} value={policy?.id}>
                    {policy?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="active" className="no-mrg-btm">
              <Select
                suffixIcon={<ArrowDown />}
                bordered={false}
                style={{ width: 100, fontWeight: 500, marginRight: 32 }}
                className="text-dark"
              >
                <Option value="all">Todos</Option>
                <Option value="true">Ativos</Option>
                <Option value="false">Inativos</Option>
              </Select>
            </Form.Item>
            <SearchInput
              style={{ width: 291, fontWeight: 500 }}
              placeholder="Busque por nome ou e-mail..."
            />
          </Form>
        </div>
      </div>
      <div className="gx-w-100 mrg-top-10">{tableContent}</div>
    </Wrapper>
  );
};

export default UsersList;
