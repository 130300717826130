/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Dropdown, Divider, Avatar, Menu, Switch } from 'antd';
import { Button } from '@combateafraude/react';

import Cookies from 'js-cookie';

import { ArrowDown, Lua } from '@combateafraude/icons/general';

import { useAuth } from 'hooks/auth';

import ProfileDefault from 'assets/images/profile-default.png';
import ProfileDefaultDark from 'assets/images/profile-default-dark.png';

import './styles.less';

const UserInfo = () => {
  const { logOut, loggedUser } = useAuth();
  const [isDarkTheme, setDarkTheme] = useState(false);

  const logout = useCallback(() => {
    logOut();
  }, [logOut]);

  useEffect(() => {
    setDarkTheme(Cookies.get('CAF-dark-theme'));
  }, []);

  useEffect(() => {
    const element = document.body;
    if (isDarkTheme) {
      element.classList.toggle('dark-theme');
      Cookies.set('CAF-dark-theme', 'true');
    } else {
      Cookies.remove('CAF-dark-theme');

      element.classList.remove('dark-theme');
    }
  }, [isDarkTheme]);

  const userProfile = useMemo(
    () => (
      <>
        <div className="user-profile">
          <div>
            <Avatar src={isDarkTheme ? ProfileDefaultDark : ProfileDefault} size={48} />
          </div>
          <section>
            <h3 className="max-lines-1">{loggedUser?.name || loggedUser?.docs?.name}</h3>
            <span className="max-lines-1">
              {loggedUser?.username || loggedUser?.docs?.username}
            </span>
          </section>
        </div>
        <Divider className="no-mrg-btm" />
      </>
    ),
    [isDarkTheme, loggedUser]
  );

  const menu = useMemo(
    () => (
      <div>
        {loggedUser && <>{userProfile}</>}

        <div className="gx-flex-column">
          <div className="user-menu">
            <Menu>
              <Menu.Item key="1" className="flex gx-w-100 space-between">
                <div className="flex center">
                  <Lua width={24} height={24} className="dark-mode" />
                  <span className="mrg-left-5 dark-mode">Modo Noturno</span>
                </div>
                <Switch checked={isDarkTheme} onChange={setDarkTheme} />
              </Menu.Item>
            </Menu>
            <Button
              className="btn-logout btn-custom-primary  btn-custom"
              onClick={logout}
            >
              Sair
            </Button>
          </div>
          <Divider className="no-mrg" />
          <section className="privacy-policies-terms-of-use">
            <div>
              <a
                href="https://www.caf.io/politicas/politicas-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Política de privacidade
              </a>
              <a
                href="https://www.caf.io/politicas/termos-e-condicoes-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                Termos e condições de uso
              </a>
            </div>
          </section>
        </div>
      </div>
    ),
    [loggedUser, userProfile, isDarkTheme, logout]
  );

  return (
    <div id="user-info-component">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        overlayClassName="user-dropdown"
      >
        <div className="user-dropdown-trigger">
          <Avatar
            src={isDarkTheme ? ProfileDefaultDark : ProfileDefault}
            size={28}
            className="mrg-right-20"
          />
          <span>{loggedUser.name}</span>
          <ArrowDown width={24} height={24} />
        </div>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
