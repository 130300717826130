import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import logoBifrost from 'assets/images/logo-bifrost.svg';

import UserInfo from 'components/UserInfo';

import HorizontalNav from './HorizontalNav';

import './styles.less';

const { Header: AntdHeader } = Layout;

const Header = ({ subheader }) => (
  <div id="header-component" className="gx-header-horizontal gx-inside-header-horizontal">
    <AntdHeader className="gx-header-horizontal-main">
      <div className="gx-container">
        <div className="gx-header-horizontal-main-flex">
          <Link to="/" className="gx-pointer mrg-right-45">
            <img src={logoBifrost} alt="" className="logo-img" />
          </Link>

          <div className="gx-header-horizontal-nav-curve gx-d-none gx-d-lg-flex flex-1">
            <HorizontalNav />
          </div>

          <ul className="gx-header-notifications gx-ml-auto no-pdd-right">
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </ul>
        </div>
      </div>
    </AntdHeader>

    {subheader}
  </div>
);

Header.propTypes = {
  subheader: PropTypes.node,
};

Header.defaultProps = {
  subheader: <></>,
};

export default Header;
