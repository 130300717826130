export default {
  name: 'wecheck',
  modules: [
    'wecheck_evaluation',
    'wecheck_revision',
    'wecheck_emailFilter',
    'wecheck_doubleChecksReviewer',
    'wecheck_sensitiveData',
    'wecheck_configs',
    'wecheck_reportSuspect',
    'wecheck_viewSelfie',
    'wecheck_incorrectAnalysis',
  ],
};
