/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createElement } from 'react';
import { Form, Switch } from 'antd';
import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

const HeaderSwitch = ({ name, isActive, setIsActive, title, icon, disabled }) => {
  const Icons = Object.assign(iconsGeneral, iconsUsers, iconsFace);

  return (
    <div className="header-text">
      <div>
        {Object.keys(Icons || {}).includes(icon) && createElement(Icons[icon])}
        {title}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Form.Item name={name} className="no-mrg no-pdd" initialValue={isActive}>
        <Switch
          key="header-switch"
          checked={isActive}
          onClick={setIsActive}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  );
};

export default HeaderSwitch;
