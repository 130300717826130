/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col } from 'antd';

import BackofficePanel from './BackofficePanel';
import TscopyPanel from './TscopyPanel';
import BifrostPanel from './BifrostPanel';
import OnboardingBuilderPanel from './OnboardingBuilderPanel';
import WeCheckPanel from './WeCheckPanel';

const BifrostPanels = ({ policyData, formRef, disabled }) => (
  <>
    <Row className="custom-panels">
      <Col span={12}>
        <BifrostPanel
          productActive={!!policyData?.docs?.products.bifrost}
          formRef={formRef}
          disabled={disabled}
        />
        <BackofficePanel
          productActive={!!policyData?.docs?.products.backoffice}
          formRef={formRef}
          disabled={disabled}
        />
        <OnboardingBuilderPanel
          productActive={!!policyData?.docs?.products.onboarding_builder}
          formRef={formRef}
          disabled={disabled}
        />
      </Col>
      <Col span={12}>
        <TscopyPanel
          productActive={!!policyData?.docs?.products.tscopy}
          formRef={formRef}
          disabled={disabled}
        />
        <WeCheckPanel
          productActive={!!policyData?.docs?.products.wecheck}
          formRef={formRef}
          disabled={disabled}
        />
      </Col>
    </Row>
  </>
);

export default BifrostPanels;
