/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const BifrostPanel = ({ productActive, disabled, formRef }) => (
  <CustomCollapse
    active={productActive}
    ruleName="bifrost"
    disabled={disabled}
    className="no-border-bottom custom-collapse"
    title="Bifrost"
    icon="Settings"
  >
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Usuários"
      icon="Users"
      ruleName="users"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Políticas de acesso"
      icon="LockValid"
      ruleName="policies"
      availability={[true, true, true, true]}
    />
  </CustomCollapse>
);

export default BifrostPanel;
