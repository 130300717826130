import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Typography } from 'antd';
import { Button } from '@combateafraude/react';

import { useScroll } from 'hooks/scroll';

import './styles.less';

const { Title } = Typography;

const UserSubheader = ({ loading, formRef, disabled, setDisabled }) => {
  const { isAtTop } = useScroll();
  const history = useHistory();

  const isAtTopClass = useMemo(() => (isAtTop ? 'expand' : 'retract'), [isAtTop]);

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link className="gx-link" to="/users">
            Bifrost
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Políticas de acesso</Breadcrumb.Item>
      </Breadcrumb>
    ),
    []
  );

  return (
    <section id="subheader-policies-component">
      <div className={`gx-header-horizontal-top ${isAtTopClass}`}>
        <div className="gx-container">
          <div className="subheader-content">
            <div className={`subheader-info ${isAtTopClass}`}>
              {standardBreadcrumbs}
              <div className="flex align-center">
                <Title level={5} className="subheader-name max-lines-2">
                  Políticas de acesso
                </Title>
              </div>
            </div>
            <Button
              className="btn-custom-secondary mrg-right-15"
              onClick={() => history.goBack()}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              className="btn-custom btn-custom-primary"
              onClick={async () => {
                if (disabled) {
                  setDisabled((oldState) => !oldState);
                } else {
                  await formRef.validateFields();
                  await formRef.submit();
                }
              }}
              loading={loading}
              disabled={loading}
            >
              {disabled ? 'Editar' : 'Salvar'}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

UserSubheader.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default UserSubheader;
