import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Login from 'pages/public/Login';
import Users from 'pages/private/Users/UsersList';
import PoliciesList from 'pages/private/Policies/PoliciesList';
import Policy from 'pages/private/Policies/CreateOrUpdatePolicy';
import Unauthorized from 'pages/private/Unauthorized';

import Route from './Route';

const Routes = () => (
  <Switch>
    <Route path="/login" exact component={Login} />

    <Route
      path="/users"
      exact
      component={Users}
      isPrivate
      requiredPermissions={['users:read']}
    />

    <Route
      path="/policies"
      exact
      component={PoliciesList}
      isPrivate
      requiredPermissions={['policies:read']}
    />

    <Route
      path="/policies/:id"
      exact
      component={Policy}
      isPrivate
      requiredPermissions={['policies:update']}
    />

    <Route
      path="/policies/create"
      exact
      component={Policy}
      isPrivate
      requiredPermissions={['policies:create']}
    />

    <Route path="/unauthorized" exact component={Unauthorized} isPrivate />

    <Redirect to="/users" />
  </Switch>
);

export default Routes;
