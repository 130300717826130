/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Tag } from 'antd';

import PermissionWrapper from 'routes/PermissionWrapper';

import './styles.less';

const SwitchRenderer = ({ data, openModal }) => {
  const status = data.active;

  return (
    <div
      className="switch-renderer"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100px',
      }}
    >
      <PermissionWrapper
        forbiddenContent={
          <Tag className={status ? 'tag-active' : 'tag-inactive'}>
            {status ? 'Ativo' : 'Inativo'}
          </Tag>
        }
        requiredPermissions={['users:update']}
      >
        <Switch
          style={{
            minWidth: 42,
            height: 23,
            minHeight: 23,
          }}
          checked={status}
          onChange={() => openModal(data)}
        />
      </PermissionWrapper>
    </div>
  );
};

export default SwitchRenderer;
