import React, { useEffect } from 'react';

import Wrapper from 'pages/wrapper';

const CustomerWrapper = ({ children }) => {
  useEffect(() => {}, []);
  return <Wrapper id="customer-wrapper-component">{children}</Wrapper>;
};

export default CustomerWrapper;
