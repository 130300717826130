import React, { useCallback, useState, useEffect, useRef, createElement } from 'react';
import { Row, Col, Form, Input, Select, message, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowDown } from '@combateafraude/icons/general';
import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';

import { useAuth } from 'hooks/auth';
import { useUser } from 'hooks/user';

import { useFetch } from 'services/hooks';

import Wrapper from 'pages/wrapper';

import PoliciesSelect from './components/PoliciesSelect';
import Subheader from './components/Subheader';

import productsKeys from './utils/products';

const permissionsActions = ['read', 'update', 'create', 'delete'];

const { Option } = Select;

const Policy = () => {
  const [form] = Form.useForm();
  const { id: policyId } = useParams();
  const history = useHistory();

  const { refreshUser } = useUser();
  const { loggedUser } = useAuth();

  const { post, put, loading: loadingUpdate } = useFetch();
  const { get: getPolicyEdit, data: policyData } = useFetch();

  const [disabled, setDisabled] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(true);

  const Icons = Object.assign(iconsGeneral, iconsUsers, iconsFace);

  const handleFinish = useCallback(
    async (payload) => {
      const newProductsPayload = {};
      for (let i = 0; i < productsKeys.length; i += 1) {
        const productKey = productsKeys[i];

        if (!payload[productKey.name].switch) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const newPoliciesPayload = [];
        productKey.modules.forEach((module) => {
          if (
            !payload[module]?.read &&
            !payload[module]?.create &&
            !payload[module]?.update &&
            !payload[module]?.delete
          ) {
            return;
          }

          newPoliciesPayload.push({
            name: module,
            effect: 'Allow',
            actions: permissionsActions
              .map((perm) => (payload[module][perm] ? `${module}:${perm}` : null))
              .filter((v) => v !== null),
            resources: [...(payload[module].resources || []), '*'],
          });
        });

        if (newPoliciesPayload.length === 0) {
          message.error(
            `Não é possível ${
              policyId === 'create' ? 'criar' : 'salvar'
            } uma política com um produto habilitado mas sem nenhuma permissão marcada.`
          );

          return;
        }

        newProductsPayload[productKey.name] = {
          policies: newPoliciesPayload,
        };
      }

      if (Object.keys(newProductsPayload).length === 0) {
        message.error(
          `Não é possível ${
            policyId === 'create' ? 'criar' : 'salvar'
          } uma política sem nenhum produto habilitado.`
        );

        return;
      }

      try {
        const data = {
          name: payload.name,
          icon: payload.icon,
          products: newProductsPayload,
        };
        let popupMessage = '';

        if (policyId && policyId !== 'create') {
          await put({
            url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups/${policyId}`,
            payload: data,
          });
          popupMessage = 'Política de acesso alterada com sucesso!';

          if (loggedUser?.policyGroup?.id === policyId) {
            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          }
        } else {
          await post({
            url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups`,
            payload: data,
          });
          popupMessage = 'Política de acesso criada com sucesso!';
        }

        history.goBack();
        message.success(popupMessage);
        refreshUser();
      } catch (error) {
        const createOrEdit = policyId === 'create' ? 'criar' : 'alterar';

        message.error(`Houve um problema ao ${createOrEdit} esta política.`);
      }
    },
    [policyId, history, refreshUser, loggedUser?.policyGroup?.id, put, post]
  );

  const setFormValues = useCallback(
    (data) => {
      if (!data) return;

      const formValues = {
        name: data?.name,
        icon: data?.icon,
      };

      productsKeys.forEach((productKey) => {
        const product = data.products[productKey.name];

        if (product && product.policies) {
          product.policies.forEach((policy) => {
            productKey.modules.forEach((module) => {
              if (policy.name === module) {
                formValues[module] = {
                  read: policy.actions.includes(`${module}:read`),
                  create: policy.actions.includes(`${module}:create`),
                  delete: policy.actions.includes(`${module}:delete`),
                  update: policy.actions.includes(`${module}:update`),
                  resources: policy.resources?.filter((resource) => resource !== '*'),
                };
              }
            });
          });
        }
      });

      form.setFieldsValue(formValues);
    },
    [form]
  );

  const getPolicy = useCallback(async () => {
    try {
      const response = await getPolicyEdit({
        url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups/${policyId}`,
        config: {},
      });
      setFormValues(response?.docs);
    } catch (error) {
      const createOrEdit = policyId === 'create' ? 'criação' : 'alteração';
      message.error(`Houve um problema ao obter dados para ${createOrEdit} de política.`);

      history.goBack();
    }

    setLoadingEdit(false);
  }, [getPolicyEdit, policyId, setFormValues, history]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    if (policyId && !policyData) {
      getPolicy();
    }
  }, [getPolicy, policyData, policyId]);

  return (
    <Wrapper
      id="policies-create"
      subheader={
        <Subheader
          loading={loadingUpdate}
          formRef={form}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      }
    >
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        {loadingEdit ? (
          <Spin className="flex center mrg-top-50" />
        ) : (
          <div>
            <Row className="mrg-vertical-30">
              <Col span={9}>
                <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={2} className="mrg-left-15">
                <Form.Item label="Ícone" name="icon" rules={[{ required: true }]}>
                  <Select
                    style={{ width: 126 }}
                    suffixIcon={<ArrowDown />}
                    disabled={disabled}
                    dropdownMatchSelectWidth={false}
                    popupClassName="select-icons"
                  >
                    {Object.keys(Icons || {})?.map((icon) => (
                      <Option key={icon} value={icon}>
                        {createElement(Icons[icon])}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <PoliciesSelect policyData={policyData} formRef={form} disabled={disabled} />
          </div>
        )}
      </Form>
    </Wrapper>
  );
};

export default Policy;
