import React, { createContext, useState, useCallback, useContext } from 'react';
import { message } from 'antd';

import api from 'services/api';

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [reloadUser, setReloadUser] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const clearData = useCallback(() => {
    setUser();
  }, []);

  const loadUser = useCallback(
    async ({ userId, clearData: clearDataParam }) => {
      try {
        if (clearDataParam) clearData();

        setLoadingUser(true);

        const { data: response } = await api.get(
          `${process.env.REACT_APP_BASE_URL_AUTH_API}/users/${userId}`
        );

        setUser(response?.data);
      } catch (err) {
        if (err?.response?.status === 404) {
          const errMessage = err?.response?.data?.message || 'Usuário não encontrado.';
          message.error(errMessage);
        } else {
          message.error('Houve um problema ao buscar os dados do usuário.');
        }
      } finally {
        setReloadUser(false);
        setLoadingUser(false);
      }
    },
    [clearData]
  );

  const refreshUser = useCallback(() => {
    setReloadUser(true);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        loadUser,
        loadingUser,
        clearData,
        reloadUser,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
}

export { UserProvider, useUser };
