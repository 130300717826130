/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const TscopyPanel = ({ productActive, disabled, formRef }) => (
  <CustomCollapse
    active={productActive}
    ruleName="tscopy"
    disabled={disabled}
    className="no-border-top no-border-bottom custom-collapse"
    title="Tscopy"
    icon="Search"
  >
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Revisão"
      icon="Eye"
      ruleName="revision"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Fila Standard"
      icon="ProcessingLine"
      ruleName="queueStandard"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Fila Avançada"
      icon="Processing"
      ruleName="queueAdvanced"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="POC"
      icon="Users"
      ruleName="queuePOC"
      availability={[true, true, false, false]}
    />
  </CustomCollapse>
);

export default TscopyPanel;
