import React, { Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

// import 'antd/dist/antd.css';
import 'styles/wieldy.less';

import AppProvider from 'hooks';

import HttpInterceptor from 'utils/httpInterceptor';

import Routes from './routes';

const App = () => (
  <Suspense fallback={<></>}>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppProvider>
          <Routes />
          <HttpInterceptor />
        </AppProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </Suspense>
);

export default App;
