/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const BackofficePanel = ({ productActive, disabled, formRef }) => (
  <CustomCollapse
    active={productActive}
    ruleName="backoffice"
    disabled={disabled}
    className="no-border-top custom-collapse"
    title="Backoffice"
    icon="Admin"
  >
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Bi"
      icon="Eye"
      ruleName="bi"
      availability={[true, true, true, true]}
    />
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Execuções"
      icon="Activity"
      ruleName="executions"
      availability={[true, true, true, true]}
    />
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Clientes"
      icon="Face"
      ruleName="clients"
      availability={[true, true, true, true]}
    />
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Finanças"
      icon="DollarSign"
      ruleName="finances"
      availability={[true, true, true, true]}
    />
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Fontes de dados"
      icon="Cloud"
      ruleName="datasources"
      availability={[true, true, false, false]}
    />
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Workflows"
      icon="ToolsWench"
      ruleName="backoffice_clientWorkflows"
      availability={[true, true, false, false]}
    />
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Hub de Contas"
      icon="Users"
      ruleName="hub_accounts"
      availability={[true, false, false, false]}
    />
  </CustomCollapse>
);

export default BackofficePanel;
