/* eslint-disable react/prop-types */
import React from 'react';

import BifrostPanels from './components/BifrostPanels';

import './styles.less';

const PoliciesSelect = ({ policyData, formRef, disabled }) => (
  <div id="select-policies">
    <BifrostPanels policyData={policyData} formRef={formRef} disabled={disabled} />
  </div>
);
export default PoliciesSelect;
