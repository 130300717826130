/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import CustomCollapse from '../CustomCollapse';
import PolicyRow from '../PolicyRow';

const WeCheckPanel = ({ productActive, disabled, formRef }) => (
  <CustomCollapse
    active={productActive}
    ruleName="wecheck"
    disabled={disabled}
    className="no-border-top no-border-bottom custom-collapse"
    title="We Check"
    icon="Search"
  >
    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Avaliação"
      icon="LoupeDocumentSearch"
      ruleName="wecheck_evaluation"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Revisão"
      icon="Eye"
      ruleName="wecheck_revision"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Filtro de e-mail"
      icon="EMail"
      ruleName="wecheck_emailFilter"
      availability={[true, true, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Revisor de double check"
      icon="ProcessingLine"
      ruleName="wecheck_doubleChecksReviewer"
      availability={[true, false, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Dados sensíveis"
      icon="LockValid"
      ruleName="wecheck_sensitiveData"
      availability={[true, false, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Configurações"
      icon="Settings"
      ruleName="wecheck_configs"
      availability={[true, true, true, true]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Reportar suspeito"
      icon="Criminal"
      ruleName="wecheck_reportSuspect"
      availability={[true, false, true, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title="Ver selfie"
      icon="Face"
      ruleName="wecheck_viewSelfie"
      availability={[true, false, false, false]}
    />

    <PolicyRow
      formRef={formRef}
      disabled={disabled}
      title={`Marcar análise como "incorreta"`}
      icon="WrongCircle"
      ruleName="wecheck_incorrectAnalysis"
      availability={[true, false, true, false]}
    />
  </CustomCollapse>
);

export default WeCheckPanel;
