import React, { useRef, useEffect, useCallback, createElement } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Button, PageTitle } from '@combateafraude/react';
import { Reorder, Plus } from '@combateafraude/icons/general';
import { Form } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import _ from 'lodash';

import * as iconsGeneral from '@combateafraude/icons/general';
import * as iconsUsers from '@combateafraude/icons/users';
import * as iconsFace from '@combateafraude/icons/face';
import { toFormatDate } from 'utils/formatters';
import PermissionWrapper from 'routes/PermissionWrapper';

import SearchInput from 'components/Form/SearchInput';
import useDynamoTable from 'components/List/hooks/useDynamoTable';

import ActionsList from '../PoliciesActions/PoliciesActionsList';

import Wrapper from '../wrapper';

const PoliciesList = () => {
  const refreshListRef = useRef(() => {});
  const history = useHistory();
  const [query] = useQueryParams({
    search: StringParam,
    // product: StringParam,
  });

  const Icons = Object.assign(iconsGeneral, iconsUsers, iconsFace);

  const columns = [
    {
      key: 'name',
      title: (
        <div className="flex row">
          <span className="column-title">Nome</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (__, record) => (
        <Link to={`/policies/${record?.id}`} className="flex align-center">
          {Object.keys(Icons || {}).includes(record?.icon) &&
            createElement(Icons[record?.icon])}
          {record?.name}
        </Link>
      ),
    },
    {
      key: 'updatedAt',
      title: (
        <div className="flex row">
          <span className="column-title">Data de atualização</span>
          <div className="flex-column">
            <Reorder />
          </div>
        </div>
      ),
      dataIndex: 'updatedAt',
      sorter: (a, b) => {
        const aDate = new Date(a.updatedAt || a.createdAt).getTime();
        const bDate = new Date(b.updatedAt || b.createdAt).getTime();

        return aDate - bDate;
      },
      render: (__, record) => toFormatDate(record?.updatedAt || record?.createdAt),
    },
    {
      key: 'actions',
      render: (__, record) => (
        <ActionsList policy={record} refreshListRef={refreshListRef} />
      ),
      width: 60,
    },
  ];

  const { tableContent, refreshList, setFilterData } = useDynamoTable({
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_BIFROST_API}/policy-groups`,
      config: {
        params: {
          limit: 10,
        },
      },
    },
    queryParams: {
      search: StringParam,
      // product: StringParam,
    },
    columns,
    rowKey: 'id',
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        search: values._search ? values._search.trim() : undefined,
        // product: values.product || undefined,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      <div className="flex gx-w-100 space-between">
        <PageTitle
          title="Políticas de acesso"
          subtitle="Gerencie as políticas de acesso."
        />
        <div className="flex row mrg-top-15 mrg-btm-30">
          <Form
            layout="horizontal"
            className="flex align-center mrg-right-30"
            onValuesChange={handleFormChange}
            initialValues={{ search: query?.search }}
          >
            <SearchInput
              style={{ width: 291, fontWeight: 500 }}
              placeholder="Busque por nome"
            />
          </Form>
          <PermissionWrapper requiredPermissions={['policies:create']}>
            <div className="mrg-left-15">
              <Button
                className="btn-custom btn-custom-primary flex center"
                onClick={() => history.push('/policies/create')}
              >
                <Plus />
                <span>Adicionar política</span>
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div className="gx-w-100 mrg-top-10">{tableContent}</div>
    </Wrapper>
  );
};

export default PoliciesList;
